<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        @change="
          getGradeList(),
            (listQuery.college_id = null),
            (listQuery.major_id = null),
            (listQuery.semester = null),
            (listQuery.grade_id = null),
            (listQuery.teacher_id = null),
            (listQuery.class_id = null)
        "
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getMajorList(),
            (listQuery.major_id = null),
            (listQuery.semester = null),
            (listQuery.grade_id = null),
            (listQuery.teacher_id = null),
            (listQuery.class_id = null)
        "
      >
        <el-option
          v-for="item in colleges"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in majors"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getClassList(),
            (listQuery.class_id = null),
            (listQuery.teacher_id = null)
        "
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="getTeacherList(), (listQuery.teacher_id = null)"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.teacher_id"
        placeholder="请选择教师"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="getCourse()"
        clearable
      >
        <el-option
          v-for="item in teachers"
          :key="item.teacher.id"
          :label="item.teacher.name"
          :value="item.teacher.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.school_course_id"
        placeholder="请选择课程"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in courses"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="Timedate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        class="filter-item"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.dayOfWeek"
        placeholder="请选择星期"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="星期一" :value="1" />
        <el-option label="星期二" :value="2" />
        <el-option label="星期三" :value="3" />
        <el-option label="星期四" :value="4" />
        <el-option label="星期五" :value="5" />
        <el-option label="星期六" :value="6" />
        <el-option label="星期日" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="getList">
        查找
      </el-button>
      <el-button class="filter-item" type="danger" @click="batchDel">
        批量删除
      </el-button>
    </div>

    <div class="title">
      <span>
        总课时: 共 {{ count ? count.total_num + count.stop_class_num : 0 }} 课时
      </span>
      <span>实上课时: 共 {{ count ? count.total_num : 0 }} 课时</span>
      <span>放假课时: 共 {{ count ? count.stop_class_num : 0 }} 课时</span
      ><br />

      <span>正常课时: 共 {{ count ? count.nomal_num : 0 }} 课时</span>
      <span>代课课时：共 {{ count ? count.replace_num : 0 }} 课时</span>
      <span>调课课时: 共 {{ count ? count.change_num : 0 }} 课时</span><br />

      <span>设置作业次数: 共 {{ count ? count.homework_num : 0 }} 次</span>
      <br />

      <span>本学期总出勤率: {{ count ? count.total_sign_in_rate : 0 }}%</span>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.school_course.name }}
        </template>
      </el-table-column>
      <el-table-column label="上课教师" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.teacher.name }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.college.school.name }}
        </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_course.classes.school_year +
            "~" +
            (scope.row.class_course.classes.school_year - 0 + 1)
          }}
        </template>
      </el-table-column>
      <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.semester == 1 ? "第一学期" : "第二学期" }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.major.name  }}
        </template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.grade.name }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.name }}
        </template>
      </el-table-column>
      <el-table-column label="日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.date }}
        </template>
      </el-table-column>
      <el-table-column label="星期" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.dayOfWeek == 0
              ? "星期日"
              : scope.row.dayOfWeek == 1
              ? "星期一"
              : scope.row.dayOfWeek == 2
              ? "星期二"
              : scope.row.dayOfWeek == 3
              ? "星期三"
              : scope.row.dayOfWeek == 4
              ? "星期四"
              : scope.row.dayOfWeek == 5
              ? "星期五"
              : scope.row.dayOfWeek == 6
              ? "星期六"
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="第几节" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_time_config ? scope.row.class_time_config.name : "-"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.type == 1 && scope.row.status == "normal"
              ? "正常"
              : scope.row.type == 1 && scope.row.status != "normal"
              ? "放假"
              : scope.row.type == 2
              ? "代课"
              : scope.row.type == 3
              ? "调课"
              : "-"
          }}
        </template>
      </el-table-column> -->
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.type == 1
              ? "正常"
              : scope.row.type == 2
              ? "代课"
              : scope.row.type == 3
              ? "调课"
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="放假状态" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.status == "stop_class"?"放假":"正常"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.type }}
          <el-tag type="danger"></el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handleJobManagement(scope.row)"
            >作业管理</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="handleStudent(scope.row)"
            >查看学生</el-button
          >
          <el-button
            size="mini"
            :type="scope.row.status=='normal'?'warning':'info'"
            @click="changeStateHandler(scope.row.id,scope.row.status)"
            >设为{{scope.row.status=="normal"?"放假":"上课"}}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        school_id: null,
        college_id: null,
        major_id: null,
        school_year: null,
        semester: null,
        grade_id: null,
        class_id: null,
        type: null,
      },
      list: [],
      total: null,
      listLoading: false,
      form: {
        id: "",
      },
      schools: [],
      colleges: [],
      majors: [],
      grades: [],
      classes: [],
      teachers: [],
      courses: [],
      count: null,
      Timedate: null,
      multipleSelection: [],
    };
  },
  created() {
    this.getCollegeList();
    // this.getList();
  },
  watch: {
    Timedate(val, old) {
      if (val) {
        this.listQuery.start_at = val[0];
        this.listQuery.end_at = val[1];
      } else {
        this.listQuery.start_at = null;
        this.listQuery.end_at = null;
      }
    },
  },
  methods: {
    getCourse() {
      this.courses = [];
      request({
        url: "/api/schoolend/eduAdmin/courseList",
        method: "get",
        params: {
          limit: 99999999999999,
          teacher_id: this.listQuery.teacher_id,
          college_id: this.listQuery.college_id,
          school_year: this.listQuery.school_year,
          class_id: this.listQuery.class_id,
          semester: this.listQuery.semester,
        },
      }).then((response) => {
        let data = response.data.data;
        data.forEach((item) => {
          this.courses.push(item.school_course);
        });
        this.$forceUpdate(); //强制更新
      });
    },
    handleSelectionChange(val) {
      console.log(val, "=========================");
      this.multipleSelection = val;
    },
    batchDel() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请选择要删除的课");
        return;
      }
      let detList = [];
      this.multipleSelection.forEach((element) => {
        detList.push(element.id);
      });
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/schedule/manyDel",
            method: "post",
            data: {
              school_id: this.listQuery.school_id,
              schedule_ids: detList,
            },
          }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取教师-----顶部筛选
    getTeacherList() {
      request({
        url: "/api/schoolend/eduAdmin/courseList",
        method: "get",
        params: {
          limit: 99999999999999,
          school_id: this.listQuery.school,
          college_id: this.listQuery.college_id,
          class_id: this.listQuery.class_id,
          is_select: 1,
        },
      }).then((response) => {
        this.teachers = response.data;
        this.$forceUpdate(); //强制更新
      });
    },
    getSchoolList() {
      request({
        url: "/api/schoolend/school/list",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 99999999999999999,
          // school_id: this.listQuery.school_id,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getMajorList() {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
        },
      }).then((response) => {
        this.majors = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 99999999999999999,
          school_id: this.listQuery.school_id,
          // school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          grade_id: this.listQuery.grade_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    handleJobManagement(row) {
      this.$router.push(
        `/schedule/operation?schedule_id=${row.id}&data=${JSON.stringify(row)}`
      );
    },
    handleStudent(row) {
      this.$router.push(
        `/arrange/student?schedule_id=${row.id}&class_id=${row.class_course.class_id}`
      );
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/schedule/scheduleLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.count = response.data.count;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    async changeStateHandler(id,status){
      try{
        await this.$confirm(`确认修改为${status="normal"?"放假":"正常上课"}？`,"警告",{type:"warning"})
        await request({
          url: "/api/schoolend/schedule/changeStatus",
          method: "post",
          data: {id},
        });
        this.$message({message:"修改成功",type:"success"})
        this.getList();
      }catch(err){
        console.log(err)
      }
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 10px;
  span {
    margin-right: 10px;
  }
}
.tip_box {
  display: flex;
  align-items: center;
  .tip {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 5px;
  }
  .color_1 {
    background: black;
  }
  .color_2 {
    background: #11eeee;
  }
  .color_3 {
    background: #4db34d;
  }
  .color_4 {
    background: red;
  }
}
</style>
